@import "../../mixin";

.Articles {
  background-color: white;
  //padding-bottom: 50px;
  margin: 0;
  padding: 0;

  .Title_Box {
    width: 60%;
    padding: 100px 0 100px 0;
    margin: auto;

    .Title_Pur {
      font-size: 40px;
      font-weight: 600;
      padding-bottom: 30px;
      border-bottom: 1px solid #cfcfcf;
    }

    .folder_dow {
      padding-top: 30px;
      padding-left: 30px;
      border-top: 1px solid #cfcfcf;
      margin-top: 50px;

      p {
        font-size: 20px;
        font-weight: 600;
        padding-bottom: 30px;
        color: #355892;
      }

      button {
        text-decoration: none;
        font-size: 15px;
        color: #355892;
        font-weight: 600;
        padding: 20px 30px;
        border: 1px solid #cfcfcf;
        border-radius: 5px;

        &:hover {
          background-color: #355892;
          border: 1px solid #d6d6d6;
          color: #fff;
          cursor: pointer;
        }
      }
    }

    .Articles_Box {
      span {
        font-size: 20px;
        font-weight: 600;
      }
      p {
        line-height: 30px;
        padding-top: 20px;
        font-size: 17px;
        padding-left: 30px;
        span {
          font-weight: 500;
        }
      }
      &::before {
        content: "";
        position: absolute;
        margin-top: 30px;
        height: 440px;
        border-left: 5px solid #355892;
      }
    }
  }
}

@include mobile {
  .Articles {
    .Title_Box {
      width: 90%;
      padding: 50px 0 50px 0;

      .Title_Pur {
        font-size: 5vw;
        padding-bottom: 10px;
      }

      .folder_dow {
        padding-top: 10px;
        padding-left: 20px;
        margin-top: 30px;

        p {
          font-size: 4vw;
          padding-bottom: 10px;
        }

        button {
          text-decoration: none;
          font-size: 3vw;
          color: #355892;
          font-weight: 600;
          padding: 2vw 3vw;
          border: 1px solid #cfcfcf;
          border-radius: 5px;
        }
      }

      .Articles_Box {
        span {
          font-size: 4vw;
        }
        p {
          line-height: 5vw;
          padding-top: 10px;
          font-size: 3vw;
          padding-left: 20px;
        }
        &::before {
          margin-top: 10px;
        }
      }
    }
  }
}
