@import "../../mixin";

.Academic_Award_Box {
  background-color: #fff;
}

.content_box {
  width: 60%;
  padding: 100px 0;
  margin: auto;

  .title_pur {
    font-size: 40px;
    font-weight: 600;
    padding-bottom: 30px;
    border-bottom: 1px solid #cfcfcf;
  }

  .content {
    padding-top: 30px;
    line-height: 30px;
    position: relative;
    margin: 0px;

    &::before {
      content: "";
      position: absolute;
      height: 390px;
      border-left: 5px solid #355892;
    }

    header {
      font-size: 20px;
      font-weight: 600;
      padding-left: 30px;

      .header_p {
        margin: 0;
      }
    }

    .content_p {
      font-size: 16px;
      font-weight: 400;
      padding-left: 30px;
    }
  }
}

@include mobile {
  .content_box {
    width: 90%;
    padding: 50px 0;

    .title_pur {
      font-size: 5vw;
      padding-bottom: 20px;
    }

    .content {
      line-height: 5vw;

      header {
        font-size: 5vw;
        font-weight: 600;
        padding-left: 20px;

        h4 {
          margin-bottom: 10px;
        }
      }

      .content_p {
        font-size: 3vw;
        padding-left: 20px;
      }
    }
  }
}
