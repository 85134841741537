@import "./mixin";

.Sub_Path {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
  background-color: #355892;
  padding-right: 300px;
  margin: 0;

  ul {
    display: flex;
    list-style: none;

    a {
      text-decoration: none;

      li {
        display: inline;
        font-size: 18px;
        line-height: 30px;
        color: #e4e4e4;
        margin: 0 7px;
      }
    }

    li {
      display: inline;
      font-size: 18px;
      line-height: 30px;
      color: #e4e4e4;
      margin: 0 7px;
    }
  }
}

@include mobile {
  .Sub_Path {
    height: 30px;
    background-color: #355892;
    padding-right: 15px;
    margin: 0;

    ul {
      display: flex;
      list-style: none;

      a {
        text-decoration: none;

        li {
          display: inline;
          font-size: 3vw;
          color: #e4e4e4;
        }
      }

      li {
        display: inline;
        font-size: 3vw;
        line-height: 30px;
        color: #e4e4e4;
        margin: 0 2px;
      }
    }
  }
}
