@import "../mixin";

.listInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #c2c2c2;
  height: 100%;
  padding: 20px 0 20px 0;

  .imgInner {
    p {
      padding-bottom: 10px;
      font-size: 15px;
    }
    img {
      width: 200px;
      height: 250px;
    }
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;

    .icon_img {
      width: 20px;
      height: 20px;
      padding-right: 5px;
      margin-left: 20px;
    }

    .title {
      font-size: 28px;
      padding: 0 0 0 20px;
      margin: 0;
      font-weight: 600;
      color: rgb(34, 34, 34);
    }

    .serve_title {
      font-size: 15px;
      padding: 0 0 10px 20px;
      margin: 0;
      font-weight: 400;
      color: #696969;
      border-bottom: 1px solid #c2c2c2;
    }

    .description {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      flex-wrap: wrap;

      .leftDescription {
        .publication_date,
        .data_type,
        .subject_classification {
          margin: 0;
          font-size: 15px;
          padding: 0 0 10px 20px;
          font-weight: 300;
          color: #696969;
        }
      }

      .rightDescription {
        .organization,
        .number {
          margin: 0;
          font-size: 15px;
          padding: 0 0 10px 20px;
          font-weight: 300;
          color: #696969;
        }
      }
    }

    .btn {
      display: flex;

      .Download,
      .Preview {
        text-decoration: none;
        display: flex;
        align-items: center;
        color: inherit;
        margin-top: 10px;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .Download {
        margin-right: 10px;
      }
    }
  }
}

@include mobile {
  .listInner {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #c2c2c2;
    height: 100%;
    padding: 20px 0 20px 0;

    .imgInner {
      p {
        padding-left: 10px;
        padding-bottom: 10px;
        font-size: 3vw;
      }
      img {
        width: 30vw;
        height: 40vw;
      }
    }

    .contentContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;

      .icon_img {
        width: 20px;
        height: 20px;
        padding-right: 5px;
        margin-left: 20px;
      }

      .title {
        font-size: 5vw;
        padding: 0 0 0 10px;
      }

      .serve_title {
        font-size: 3vw;
        padding: 0 0 10px 10px;
        margin: 0;
        font-weight: 400;
        color: #696969;
        border-bottom: 1px solid #c2c2c2;
      }

      .description {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        flex-wrap: wrap;

        .leftDescription {
          .publication_date,
          .data_type,
          .subject_classification {
            font-size: 3vw;
            padding-left: 10px;
          }
        }

        .rightDescription {
          .organization,
          .number {
            font-size: 3vw;
            padding-left: 10px;
          }
        }
      }

      .btn {
        display: flex;

        .Download,
        .Preview {
          font-size: 3vw;
          margin-top: 10px;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .Download {
          margin-right: 0px;
        }
      }
    }
  }
}
