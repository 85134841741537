@import "./mixin";

.Header {
  display: flex;
  align-items: center;
  width: 100vw;
  background-color: rgb(240, 241, 244);
  flex-direction: column;

  .Logo_Box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 44px;
    margin-bottom: 35px;

    .Logo {
      width: 118px;
      height: 104px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .Menu_Bar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 58px;
    flex-direction: row;
    border-top: 1px solid rgb(207, 207, 207);
    background-color: white;

    .Btn {
      display: flex;
      width: 152.22px;
      height: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;

      &:hover {
        background-color: rgb(53, 88, 146);
        color: white;
        cursor: pointer;
      }

      .DropDown {
        flex-direction: column;
        position: absolute;
        top: 56px;
        z-index: 1;
        a {
          text-decoration: none;
        }

        div {
          display: flex;
          width: 152.22px;
          height: 40.04px;
          align-items: center;
          justify-content: center;
          background-color: white;
          color: black;
          font-size: 13px;
          &:hover {
            cursor: pointer;
            background-color: rgb(233, 233, 233);
          }
        }
      }
    }
  }
}

@include mobile {
  .Header {
    display: flex;
    align-items: center;
    width: 100vw;
    background-color: rgb(240, 241, 244);
    flex-direction: column;

    .Logo_Box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;

      .Logo {
        width: 50px;
        height: 50px;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .Menu_Bar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 50px;
      flex-direction: row;
      border-top: 1px solid rgb(207, 207, 207);
      background-color: white;

      .Btn {
        display: flex;
        width: 100px;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        font-size: 2.5vw;

        &:hover {
          background-color: rgb(53, 88, 146);
          color: white;
          cursor: pointer;
        }

        .DropDown {
          flex-direction: column;
          position: absolute;
          top: 50px;
          z-index: 1;
          a {
            text-decoration: none;
          }

          div {
            display: flex;
            width: 16.5vw;
            height: 40.04px;
            align-items: center;
            justify-content: center;
            background-color: white;
            color: black;
            font-size: 2.5vw;
          }
        }
      }
    }
  }
}
