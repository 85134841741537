@import "../mixin";

.Main {
  background-color: rgb(240, 241, 244);
  margin: 0;

  .Banner {
    position: relative;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    .slick-prev,
    .slick-next {
      width: 15px;
      height: 26px;
      z-index: 1;
      opacity: 0.6;
      &::before {
        content: none;
      }
    }
    .slick-prev {
      left: 40px;
    }
    .slick-next {
      right: 40px;
    }
    .slick-slide {
      //position: relative;
      margin: 0;
    }
    .slick-dots {
      bottom: 10px;
      //padding: 20px;

      li {
        button:before {
          color: white;
          font-size: 10px;
        }

        &.slick-active {
          button:before {
            color: white;
            font-size: 10px;
          }
        }
      }
    }
    img {
      width: 100%;
    }
    .Banner_Text {
      position: absolute;
      transform: translate(-50%, -50%);

      top: 50%;
      left: 40%;
      color: white;
      white-space: nowrap;

      .Title_Text {
        color: #ffffff;
        font-weight: 600;
        font-size: 2vw;
        text-shadow: 2px 2px 50px #4e4e4e;
      }

      p {
        margin-top: 15px;
        color: #ffffff;
        font-weight: 200;
        font-size: 1.5vw;
        line-height: 2vw;
        text-shadow: 2px 2px 50px #4e4e4e;
      }
    }
    .slider-border {
      position: absolute;
      height: 22px;
      width: 100%;
      padding: 0;
      margin: 0;
      bottom: -20px;
      background-color: #355892;
    }
    .Banner_Button {
      position: absolute;
      right: 120px;
      bottom: 39px;
      width: 150px;
      bottom: 50px;
      box-shadow: 3px 3px 10px rgb(29, 29, 29);
      &:hover {
        cursor: pointer;
        opacity: 80%;
        transition: 1s;
      }
    }
  }
  .Artclass {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 400px;

    .Left_Art_Box,
    .Right_Art_Box {
      display: flex;
      position: relative;
      width: 590px;
      height: 280px;
      margin: 50px;
      border-left: solid 8px #355892;
      background-color: #ffffff;
      flex-direction: column;
      .Art_Box_Title {
        font-size: 22px;
        font-weight: 400;
        text-align: center;
        padding: 23px;
        background-color: #f8f8f8;
        position: relative;
        font: inherit;
        vertical-align: baseline;
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          text-align: center;
          bottom: 0;
          width: 46px;
          border-bottom: 1px solid #355892;
          transform: translateX(-50%);
        }
      }
      a {
        text-decoration: none;
        color: #4d4d4d;
      }
      p {
        font-size: 14px;
        font-weight: 300;
        line-height: 40px;
        padding: 0px 0vw 10px 20px;
        color: #4d4d4d;
      }
      img {
        width: 54px;
        position: absolute;
        right: 40px;
        bottom: 30px;
        &:hover {
          cursor: pointer;
          transform: translateX(18px);
          transition: 1s;
        }
        &:not(:hover) {
          transition: 1s;
        }
      }
    }
  }

  .Quick_Menu {
    display: flex;
    justify-content: center;
    width: 100%;
    .menu_bar {
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      margin-top: 115px;
      margin-bottom: 124px;
      position: relative;
      flex-shrink: 0;

      a {
        text-decoration: none;
      }

      .Menu {
        display: flex;
        width: 234px;
        height: 81px;
        background-color: white;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        color: #141414;
        font-weight: 400;
        &:hover {
          cursor: pointer;
          box-shadow: 0px -8px 0px #355892 inset;
          transition: 0.5s;
        }
        /*
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          height: 63px;
          border-left: 1px solid #355892;
          transform: translate(10%, -50%);
        }

        &.no-after:after {
          display: none;
        }
*/
        img {
          width: 40px;
          height: 40px;
          padding-right: 44px;
        }
        .Banner_Text {
          position: absolute;
          transform: translate(-50%, -50%);

          top: 50%;
          left: 40%;
          color: white;
          white-space: nowrap;

          .Title_Text {
            color: #ffffff;
            font-weight: 600;
            font-size: 3vw;
            text-shadow: 2px 2px 50px #4e4e4e;
          }

          p {
            margin-top: 15px;
            color: #ffffff;
            font-weight: 200;
            font-size: 1.5vw;
            line-height: 2vw;
            text-shadow: 2px 2px 50px #4e4e4e;
          }
        }
      }
      .line {
        width: 1px;
        height: 63px;
        border-left: 1px solid #355892;
        padding: 0;
        margin: 0;
      }
    }
  }

  .Serve_Banner {
    position: relative;

    .text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 38px;
      font-weight: 800;
      color: #ffffff;
      text-shadow: 2px 2px 50px #4e4e4e;
      letter-spacing: 1px;
      white-space: nowrap;
    }

    img {
      width: 100%;
      height: 232px;
      object-fit: cover;
    }
  }
}

@include laptop {
  .Main {
    .Banner {
      .Banner_Text .Title_Text {
        font-size: 30px;
      }
      p {
        font-size: 18px;
      }
    }

    .Quick_Menu {
      .menu_bar {
        .Menu {
          font-size: 22px;
          width: 20vw;
          height: 80px;
          img {
            width: 28px;
            padding-right: 2vw;
          }
        }
      }
    }

    .Serve_Banner {
      .text {
        span {
          font-size: 2.5vw;
        }
      }
    }
  }
}

@include mobile {
  .Main {
    background-color: rgb(240, 241, 244);
    margin: 0;

    .Banner {
      .slick-prev {
        left: 10px;
      }
      .slick-next {
        right: 10px;
      }

      img {
        width: 100%;
      }

      .Banner_Text {
        position: absolute;
        transform: translate(-50%, -50%);

        top: 50%;
        left: 50%;

        .Title_Text {
          color: #ffffff;
          font-weight: 600;
          font-size: 4vw;
          text-shadow: 2px 2px 50px #4e4e4e;
        }

        p {
          margin-top: 1px;
          color: #ffffff;
          font-weight: 200;
          font-size: 8px;
          line-height: 10px;
          text-shadow: 2px 2px 50px #4e4e4e;
        }
      }
      .slider-border {
        position: absolute;
        height: 12px;
        width: 100%;
        padding: 0;
        margin: 0;
        bottom: 0px;
        background-color: #355892;
      }
      .Banner_Button {
        position: absolute;
        right: 30px;
        bottom: 10px;
        width: 10vw;
        bottom: 20px;
        box-shadow: 3px 3px 10px rgb(29, 29, 29);
        &:hover {
          cursor: pointer;
          opacity: 80%;
          transition: 1s;
        }
      }
    }
    .Artclass {
      display: flex;
      margin-top: 50px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 500px;

      .Left_Art_Box,
      .Right_Art_Box {
        display: flex;
        position: relative;
        width: 80vw;
        height: 300px;
        margin: 15px;

        .Art_Box_Title {
          font-size: 11px;
          font-weight: 400;
          text-align: center;
          padding: 23px;
          background-color: #f8f8f8;
          position: relative;
          font: inherit;
          vertical-align: baseline;
          &::after {
            content: "";
            position: absolute;
            left: 50%;
            text-align: center;
            bottom: 0;
            width: 46px;
            border-bottom: 1px solid #355892;
            transform: translateX(-50%);
          }
        }
        a {
          text-decoration: none;
          color: #4d4d4d;
        }
        p {
          font-size: 11px;
          font-weight: 300;
          line-height: 40px;
          padding: 0px 0vw 10px 12px;
          color: #4d4d4d;
        }
        img {
          width: 54px;
          position: absolute;
          right: 40px;
          bottom: 30px;
          &:hover {
            cursor: pointer;
            transform: translateX(18px);
            transition: 1s;
          }
          &:not(:hover) {
            transition: 1s;
          }
        }
      }
    }

    .Quick_Menu {
      display: flex;
      justify-content: center;
      width: 100%;
      .menu_bar {
        width: 70%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row;
        margin-top: 10px;
        margin-bottom: 10px;
        position: relative;
        flex-shrink: 0;

        a {
          text-decoration: none;
        }

        .Menu {
          display: flex;
          width: 110px;
          height: 60px;
          background-color: white;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          color: #141414;
          font-weight: 400;
          margin-bottom: 10px;
          margin-right: 1px;

          &:hover {
            cursor: pointer;
            box-shadow: 0px -8px 0px #355892 inset;
            transition: 0.5s;
          }

          img {
            width: 20px;
            height: 20px;
            padding-right: 10px;
          }
        }
        .line {
          display: none;
        }
      }
    }

    .Serve_Banner {
      position: relative;
      margin-top: 20px;

      .text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 3vw;
        font-weight: 800;
        color: #ffffff;
        text-shadow: 2px 2px 50px #4e4e4e;
        letter-spacing: 1px;
        white-space: nowrap;
      }

      img {
        width: 100%;
        height: 102px;
        object-fit: cover;
      }
    }
  }
}
