@import "../../mixin";

.Society_News_Box {
  .content_box {
    width: 60%;
    padding: 100px 0;
    margin: auto;
  }

  .title_pur {
    font-size: 40px;
    font-weight: 600;
    padding-bottom: 30px;
    border-bottom: 1px solid #cfcfcf;
  }

  .notice {
    padding: 80px 0;

    .page-title {
      margin-bottom: 60px;

      h3 {
        font-size: 28px;
        color: #333333;
        font-weight: 400;
        text-align: center;
      }
    }

    .board-search {
      .search-window {
        padding: 15px 0;
        background-color: #f0f1f4;

        .search-wrap {
          position: relative;
          margin: 0 auto;
          width: 80%;
          max-width: 564px;

          input {
            height: 40px;
            width: 100%;
            font-size: 14px;
            padding: 7px 14px;
            border: 1px solid #ccc;

            &:focus {
              border-color: #333;
              outline: 0;
              border-width: 1px;
            }
          }

          .btn {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 108px;
            padding: 0;
            font-size: 16px;
          }
        }
      }
    }

    .board-list {
      .board-table {
        font-size: 15px;
        width: 100%;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;

        a {
          color: #333;
          display: inline-block;
          line-height: 1.4;
          word-break: break-all;
          vertical-align: middle;
          text-decoration: none;
          font-weight: 500;

          &:hover {
            text-decoration: underline;
          }
        }

        th {
          text-align: center;
        }

        .th-num {
          width: 100px;
          text-align: center;
        }

        .th-date {
          width: 200px;
        }

        th,
        td {
          padding: 14px 0;
        }

        tbody {
          td {
            border-top: 1px solid #e7e7e7;
            text-align: center;
          }

          th {
            padding-left: 28px;
            padding-right: 14px;
            border-top: 1px solid #e7e7e7;
            text-align: left;

            a {
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .btn {
    display: inline-block;
    padding: 0 30px;
    font-size: 15px;
    font-weight: 400;
    background: transparent;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    border: 1px solid transparent;
    text-transform: uppercase;
    border-radius: 0;
    transition: all 0.3s;

    &.btn-dark {
      background: #555;
      color: #fff;

      &:hover,
      &:focus {
        background: #355892;
        border-color: #355892;
        color: #fff;
      }
    }
  }
}

@include mobile {
  @import "../../mixin";

  .Society_News_Box {
    .content_box {
      width: 90%;
      padding: 50px 0;

      .title_pur {
        font-size: 5vw;
      }

      .notice {
        padding: 10px 0;

        .page-title {
          margin-bottom: 30px;

          .container {
            h3 {
              font-size: 5vw;
            }
          }
        }

        .board-search {
          .container {
            .search-window {
              padding: 15px 0;
              background-color: #f0f1f4;

              .search-wrap {
                width: 90%;
                .btn {
                  width: 10vw;
                  font-size: 3vw;
                }
              }
            }
          }
        }

        .board-list {
          .container {
            .board-table {
              font-size: 3vw;

              a {
                line-height: 5vw;
                word-break: break-all;
                vertical-align: middle;
                text-decoration: none;
              }

              .th-num {
                width: 7vw;
              }

              .th-date {
                width: 2vw;
              }

              th,
              td {
                padding: 10px 0;
              }
            }
          }
        }
      }
    }
  }
}
