.Back-To-Top {
  display: inline-block;
  background-color: #355892;
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  color: white;
  font-weight: 700;
  line-height: 50px;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 1000;

  &.visible {
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
    background-color: #c2c2c2;
    transition: 0.5s;
  }
  &:not(:hover) {
    transition: 0.5s;
  }

  &:active {
    background-color: #f0f1f4;
  }
}
