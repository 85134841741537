@import "../../mixin";
.back {
  display: flex;
  justify-content: center;

  .Articles_Box {
    margin-left: 300px;
    margin-right: 300px;
    margin-top: 100px;

    .Top {
      border-bottom: 1px solid #cfcfcf;
      margin-bottom: 100px;
      p {
        font-weight: 400;
        font-size: 34px;
        margin: 25px 0;
        padding-left: 10px;
      }
    }
    .contents {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      color: #333;

      .mainText {
        word-spacing: 1px;
        letter-spacing: 0.01em;
        font-size: 16px;
        line-height: 25px;
        flex: 1 1 60%;
        padding: 0 15px;
        width: 650px;
        min-width: 600px;

        .title {
          font-size: 26px;
          text-align: center;
          margin: 30px 0 30px 0;
          &:hover {
            color: gray;
          }
        }
        .authors {
          font-size: 13px;
          text-align: center;
          margin-bottom: 40px;
        }
        .middleTitle {
          text-align: center;
          margin-top: 40px;
          margin-bottom: 10px;
        }
      }

      .right_box {
        flex: 1 1 30%;
        margin-left: 20px;
        min-width: 50px;
        width: 390px;
        color: #333;

        .right_title {
          font-size: 1.5em;
          margin-bottom: 20px;
          line-height: 1.6;
          padding: 10px 0 0;
          font-weight: 400;
          color: #444;
        }
        .right_container {
          display: flex;
          flex-direction: row;
          align-items: start;
          margin-right: 15px;
          img {
            margin-top: 3px;
          }
          .right_cite_description {
            padding-left: 10px;
            font-size: 14px;
            color: #444;
            margin-bottom: 30px;

            &:hover {
              cursor: pointer;
              color: #000;
            }
          }
        }
        ul {
          list-style: none;
          padding: 0;

          li {
            font-size: 14px;
            margin-bottom: 10px;
            &:hover {
              color: #000;
              cursor: pointer;
            }

            img {
              margin-right: 15px;
            }
          }
        }
      }
    }
    .Foot {
      text-align: center;
      padding-top: 1em;
      margin: 0;
      margin-top: 100px;
      border-top: 1px solid #eee;
      color: #666;
    }
  }
}

@include mobile {
  .back {
    .Articles_Box {
      margin: 0;
      width: 100%;

      .Top {
        border-bottom: 1px solid #cfcfcf;
        margin-bottom: 50px;
        p {
          font-weight: 400;
          font-size: 5vw;
          margin: 20px 0;
          padding-left: 10px;
        }
      }
      .contents {
        width: 100%;

        .mainText {
          word-spacing: 1px;
          letter-spacing: 0.01em;
          font-size: 3vw;
          line-height: 5vw;
          max-width: 100%;
          min-width: 10px;

          .title {
            font-size: 5vw;
            margin: 0;
          }
          .authors {
            font-size: 3vw;
          }
          .middleTitle {
            text-align: center;
            margin-top: 40px;
            margin-bottom: 10px;
          }
        }

        .right_box {
          flex: 1 1 30%;
          margin-left: 20px;
          min-width: 50px;
          width: 390px;
          color: #333;

          .right_title {
            font-size: 1.5em;
            margin-bottom: 20px;
            line-height: 1.6;
            padding: 10px 0 0;
            font-weight: 400;
            color: #444;
          }
          .right_container {
            display: flex;
            flex-direction: row;
            align-items: start;
            img {
              margin-top: 3px;
            }
            .right_cite_description {
              padding-left: 10px;
              font-size: 14px;
              color: #444;
              margin-bottom: 30px;

              &:hover {
                cursor: pointer;
                color: #000;
              }
            }
          }
          ul {
            list-style: none;
            padding: 0;

            li {
              font-size: 14px;
              margin-bottom: 10px;
              &:hover {
                color: #000;
                cursor: pointer;
              }

              img {
                margin-right: 15px;
              }
            }
          }
        }
      }
      .Foot {
        margin-top: 30px;
        font-size: 3vw;
      }
    }
  }
}
