@import "./mixin";

.Footer {
  display: flex;
  flex-direction: column;
  font: inherit;
  background-color: rgb(240, 241, 244);

  .Upper_Box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 75px 0 75px 0;

    .Logo_Box {
      display: flex;
      flex-direction: row;
      img {
        width: 56px;
        height: 49px;
      }
      p {
        font-size: 24px;
        padding-left: 10px;
      }
    }

    .Info_Box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      div {
        display: flex;
        flex-direction: row;
        margin: 0;

        p {
          padding-left: 10px;
          text-decoration: none;
          font-size: 15px;
          color: #141414;
        }
        img {
          width: 20px;
        }
      }
    }
  }

  .Under_Box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 100%;
    background-color: white;
    img {
      cursor: pointer;
    }
    div {
      display: flex;
      flex-direction: row;
      p {
        margin: 30px 5px 0px 5px;
        text-decoration: none;
        font-size: 15px;
        color: #141414;
      }
      .Clickable_Text {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@include mobile {
  .Footer {
    display: flex;
    flex-direction: column;
    font: inherit;
    background-color: rgb(240, 241, 244);

    .Upper_Box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 25px 0 25px 0;

      .Logo_Box {
        display: flex;
        flex-direction: row;
        img {
          width: 20px;
          height: 20px;
          padding-top: 5px;
        }
        p {
          font-size: 12px;
          padding-left: 5px;
        }
      }

      .Info_Box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        div {
          display: flex;
          flex-direction: row;
          margin: 0;

          p {
            padding-left: 5px;
            text-decoration: none;
            font-size: 8px;
            color: #141414;
          }
          img {
            width: 10px;
          }
        }
      }
    }

    .Under_Box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 120px;
      width: 100%;
      background-color: white;
      img {
        cursor: pointer;
        margin-top: 30px;
        width: 20px;
      }
      div {
        display: flex;
        flex-direction: row;
        p {
          margin: 15px 2px 0px 2px;
          text-decoration: none;
          font-size: 8px;
          color: #141414;
        }
        .Clickable_Text {
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      p {
        font-size: 1.8vw;
      }
    }
  }
}
