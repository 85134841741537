@import "../../mixin";

.Post-Box {
  background-color: #fff;

  .content_box {
    width: 60%;
    padding: 100px 0;
    margin: auto;

    .title_box {
      .title {
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .description_box {
        display: flex;
        flex-direction: row;
        padding-bottom: 10px;
        border-bottom: 1px solid #cfcfcf;

        .description {
          margin-right: 10px;
          color: #cfcfcf;
        }
      }
    }

    .body {
      .bodyText {
        font-size: 25px;
      }
    }
  }
}

@include mobile {
  .Post-Box {
    .content_box {
      width: 90%;
      padding: 50px 0;
      margin: auto;

      .title_box {
        .title {
          font-size: 5vw;
          font-weight: 600;
          margin-bottom: 10px;
        }

        .description_box {
          font-size: 4vw;
          padding-bottom: 5px;

          .description {
            margin-right: 10px;
          }
        }
      }

      .body {
        .bodyText {
          font-size: 3vw;
        }
      }
    }
  }
}
