@import "../../mixin";

.submission_information_Box {
  background-color: #fff;

  .content_box {
    width: 60%;
    padding: 100px 0;
    margin: auto;

    .title_pur {
      font-size: 40px;
      font-weight: 600;
      padding-bottom: 30px;
      border-bottom: 1px solid #cfcfcf;
    }

    .content {
      padding-top: 30px;
      line-height: 30px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        height: 280px;
        border-left: 5px solid #355892;
      }

      header {
        font-size: 20px;
        font-weight: 600;
        padding-left: 30px;
        p {
          margin: 0;
        }
        h4 {
          margin: 0;
        }
      }
    }

    .content-p {
      font-size: 16px;
      font-weight: 400;
      padding-left: 30px;
      padding-bottom: 20px;
    }

    .center {
      margin-bottom: 30px;
    }

    .content_btn {
      text-decoration: none;
      font-size: 17px;
      color: #355892;
      font-weight: 600;
      border: 1px solid #d6d6d6;
      padding: 10px;
      border-radius: 5px;
      transition: 1s;

      &:hover {
        background-color: #355892;
        border: 1px solid #d6d6d6;
        color: #fff;
      }
    }

    .tg {
      border: 1px solid #c2c2c2;
      margin: 30px;
      border-collapse: collapse;

      th,
      td {
        border: 1px solid #c2c2c2;
        padding: 10px;
        vertical-align: middle;
      }
    }
  }
}

@include mobile {
  .submission_information_Box {
    .content_box {
      width: 90%;
      padding: 50px 0;

      .title_pur {
        font-size: 5vw;
        padding-bottom: 10px;
      }

      .content {
        padding-top: 20px;
        line-height: 5vw;

        &::before {
          content: "";
          position: absolute;
          height: 280px;
          border-left: 5px solid #355892;
        }

        header {
          font-size: 5vw;
          line-height: 6vw;
          padding-left: 20px;
          p {
            margin: 0;
          }
          h4 {
            margin: 0;
          }
        }

        .content_p {
          font-size: 3vw;
          padding-left: 20px;
        }

        .center {
          margin-bottom: 30px;
        }

        .tg {
          font-size: 3vw;
          margin-left: 20px;
        }
      }
    }
  }
}
