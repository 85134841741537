@import "../../mixin";

.Code_Of_Ethics {
  .Code-Of-Ethics_Box {
    width: 60%;
    padding: 100px 0 100px 0;
    margin: auto;

    .Content_Box {
      .Title_Pur {
        font-size: 40px;
        font-weight: 600;
        padding-bottom: 30px;
        border-bottom: 1px solid #cfcfcf;
      }

      .Content {
        padding-top: 30px;
        line-height: 30px;

        &::before {
          content: "";
          position: absolute;
          height: 730px;
          border-left: 5px solid #355892;
        }

        header {
          font-size: 20px;
          font-weight: 600;
          padding-left: 30px;
          margin: 0;

          h1 {
            font-size: 20px;
            font-weight: 600;
            margin: 0;
          }

          p {
            font-size: 20px;
            font-weight: 600;
            padding: 0;
            margin: 0;
          }
        }

        p {
          font-size: 16px;
          font-weight: 400;
          padding-left: 30px;
        }
      }
    }
  }
}

@include mobile {
  .Code_Of_Ethics {
    .Code-Of-Ethics_Box {
      width: 90%;
      padding: 50px 0 50px 0;

      .Content_Box {
        .Title_Pur {
          font-size: 5vw;
          padding-bottom: 10px;
        }

        .Content {
          padding-top: 30px;
          line-height: 5vw;

          header {
            font-size: 5vw;
            padding-left: 20px;

            h1,
            h2,
            p {
              font-size: 4vw;
            }
          }

          p {
            font-size: 3vw;
            padding-left: 20px;
          }
        }
      }
    }
  }
}
