@import "../../mixin";

.Membership-Box {
  .content_box {
    width: 60%;
    padding: 100px 0;
    margin: auto;
    .title_pur {
      font-size: 40px;
      font-weight: 600;
      padding-bottom: 30px;
      border-bottom: 1px solid #cfcfcf;
    }

    .content {
      padding-top: 30px;
      line-height: 30px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        height: 570px;
        border-left: 5px solid #355892;
      }

      header {
        font-size: 20px;
        font-weight: 600;
        padding-left: 30px;

        .center {
          margin-top: 0;
        }

        a {
          text-decoration: none;
          font-size: 17px;
          color: #355892;
          font-weight: 600;
          border: 1px solid #d6d6d6;
          padding: 10px;
          border-radius: 5px;
          transition: 1s;

          &:hover {
            background-color: #355892;
            border: 1px solid #d6d6d6;
            color: #fff;
          }
        }
      }

      .content_p {
        font-size: 16px;
        font-weight: 400;
        padding-left: 30px;
        padding-bottom: 20px;
      }
    }

    .center {
      margin-bottom: 30px;
    }

    .tg {
      border: 1px solid #c2c2c2;
      margin: 30px;
      border-collapse: collapse;

      th,
      td {
        border: 1px solid #c2c2c2;
        padding: 10px;
        vertical-align: middle;
      }
    }
  }
}

@include mobile {
  .Membership-Box {
    .content_box {
      width: 90%;
      padding: 50px 0;

      .title_pur {
        font-size: 5vw;
        padding-bottom: 10px;
      }

      .content {
        padding-top: 30px;
        line-height: 30px;
        position: relative;

        header {
          font-size: 4vw;
          font-weight: 600;
          padding-left: 20px;

          a {
            font-size: 4vw;
          }
        }

        .content_p {
          font-size: 3vw;
          line-height: 5vw;
          padding-left: 20px;
        }

        h5 {
          margin: 0px;
        }
      }

      .center {
        margin-bottom: 30px;
      }

      .tg {
        margin: 30px 10px 30px 20px;
        font-size: 3vw;

        th,
        td {
          padding: 5px;
        }
      }
    }
  }
}
