@import "../../mixin";

.Purpose {
  background-color: white;
  //padding-bottom: 50px;
  margin: 0;
  padding: 0;

  .Title_Box {
    width: 60%;
    padding: 100px 0 100px 0;
    margin: auto;

    .Title_Pur {
      font-size: 40px;
      font-weight: 600;
      padding-bottom: 30px;
      border-bottom: 1px solid #cfcfcf;
    }

    .Purpose {
      h1 {
        padding-top: 30px;
        padding-left: 30px;
        font-size: 28px;
      }
      p {
        line-height: 30px;
        padding-top: 20px;
        font-size: 17px;
        padding-left: 30px;
        span {
          font-weight: 500;
        }
      }
      &::before {
        content: "";
        position: absolute;
        margin-top: 30px;
        height: 220px;
        border-left: 5px solid #355892;
      }
    }

    .Greetings {
      margin-top: 100px;
      border-top: 1px solid #cfcfcf;
      padding-top: 20px;
      span {
        padding-left: 30px;
        font-size: 28px;
      }
      p {
        line-height: 30px;
        padding-top: 20px;
        font-size: 17px;
        padding-left: 30px;
      }
    }
  }
}

@include mobile {
  .Purpose {
    .Title_Box {
      width: 90%;
      padding: 50px 0 50px 0;
      margin: auto;

      .Title_Pur {
        font-size: 5vw;
        padding-bottom: 10px;
        border-bottom: 1px solid #cfcfcf;
      }

      .Purpose {
        h1 {
          padding-top: 10px;
          padding-left: 20px;
          font-size: 5vw;
        }
        p {
          line-height: 5vw;
          padding-top: 20px;
          font-size: 3vw;
          padding-left: 20px;
        }
        &::before {
          content: "";
          position: absolute;
          margin-top: 10px;
        }
      }

      .Greetings {
        margin-top: 15px;
        border-top: 1px solid #cfcfcf;
        padding-top: 20px;
        span {
          padding-left: 20px;
          font-size: 5vw;
        }
        p {
          line-height: 5vw;
          padding-top: 20px;
          font-size: 3vw;
          padding-left: 20px;
        }
      }
    }
  }
}
