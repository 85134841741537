@import "../../mixin";

.Chart {
  background-color: white;
  margin: 0;
  padding: 0;

  .Chart_Box {
    width: 60%;
    padding: 100px 0 100px 0;
    background-color: #fff;
    position: relative;
    margin: auto;

    .Title_Pur {
      font-size: 40px;
      font-weight: 600;
      padding-bottom: 30px;
      border-bottom: 1px solid #cfcfcf;
      margin-bottom: 50px;
    }

    img {
      width: 100%;
    }
  }
}

@include mobile {
  .Chart {
    .Chart_Box {
      width: 90%;
      padding: 50px 0 50px 0;

      .Title_Pur {
        font-size: 5vw;
        padding-bottom: 10px;
        margin-bottom: 30px;
      }
    }
  }
}
