@import "./mixin";

.Sub_Banner_Box {
  margin: 0;
  padding: 0;
  position: relative;
  img {
    width: 100%;
    max-height: 265px;
    object-fit: cover;
  }

  div {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translateY(-50%);
    p {
      color: #fff;
      text-shadow: 2px 2px 50px #3d3d3d;
      font-size: 45px;
      font-weight: 500;
      letter-spacing: -3px;

      span {
        font-size: 22px;
        font-weight: 200;
        letter-spacing: 0px;
      }
    }
  }
}

@include laptop {
  .Sub_Banner_Box {
    div {
      p {
        font-size: 35px;

        span {
          font-size: 18px;
        }
      }
    }
  }
}

@include mobile {
  .Sub_Banner_Box {
    div {
      p {
        font-size: 4vw;
        letter-spacing: 0px;
        span {
          font-size: 2vw;
        }
      }
    }
  }
}
