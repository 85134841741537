@import "../../mixin";
.Article_Box {
  .content_box {
    width: 60%;
    padding: 100px 0;
    margin: auto;

    .search_back {
      height: 200px;
      width: 100%;
      background-color: #f0f1f4;
      margin-top: 30px;
      border-left: 8px solid #355892;
      position: relative;
      box-sizing: border-box;

      p {
        font-size: 25px;
        margin-top: 0;
        margin-bottom: 15px;
      }

      .search_div {
        width: 70%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;

        .searchBox {
          width: 100%;
          margin-bottom: 15px;
          justify-content: center;
          align-items: center;

          label {
            .search {
              height: 33px;
              border: 1px solid #d1d1d1;
              padding-left: 10px;
              width: 75%;
            }
          }
        }
      }

      .date_dd {
        input {
          height: 35px;
          border: 1px solid #d1d1d1;
          color: #575757;
          padding-left: 10px;
          padding-right: 5px;
        }

        span {
          padding-left: 2px;
          padding-right: 2px;
        }
      }

      .select {
        width: 20%;
        height: 37px;
        outline: none;
        border: 1px solid #d1d1d1;
        font-size: 14px;
        color: #575757;
        font-weight: 400;
        margin-right: 2px;
      }
    }

    .tab {
      width: 100%;
      height: 100%;

      .tabnav {
        font-size: 0;
        width: 100%;
        border: 1px solid #ddd;
        padding-left: 0px;

        li {
          display: inline-block;
          height: 46px;
          text-align: center;
          border-right: 1px solid #ddd;

          div {
            position: relative;
            display: block;
            background: #f8f8f8;
            color: #000;
            padding: 0 30px;
            line-height: 46px;
            text-decoration: none;
            font-size: 16px;
            &:hover {
              cursor: pointer;
            }

            &:before {
              content: "";
              position: absolute;
              left: 0;
              top: 0px;
              width: 100%;
              height: 3px;
            }

            &.active:before {
              background: #355892;
            }

            &.active {
              background: #fff;
              color: #355892;
              border-bottom: 1px solid #fff;
            }

            &:hover {
              background: #fff;
              color: #355892;
            }
          }
        }
      }

      .tabcontent {
        padding: 20px;
        border: 1px solid #ddd;
        border-top: none;
      }
    }

    .Research_sub_text {
      margin: 40px 0;
      height: auto;
      border-left: 8px solid #355892;

      h1 {
        font-size: 20px;
        color: #696969;
        padding: 0 0 0 10px;
        font-weight: 600;
        margin: 0;
      }

      p {
        font-size: 16px;
        color: #696969;
        line-height: 20px;
        padding: 0 0 0 10px;
      }
    }
  }
}

@include mobile {
  .Article_Box {
    .content_box {
      width: 90%;
      padding: 50px 0;

      .search_back {
        width: 100%;
        margin-top: 0px;

        p {
          font-size: 5vw;
          margin-bottom: 25px;
        }

        .search_div {
          width: 90%;
        }

        .date_dd {
          input {
            font-size: 3vw;
            width: 40%;
          }
        }

        .select {
          width: 20%;
          height: 37px;
          outline: none;
          border: 1px solid #d1d1d1;
          font-size: 3vw;
          color: #575757;
          font-weight: 400;
          margin-right: 2px;
        }
      }

      .tab {
        width: 100%;
        height: 100%;

        .tabnav {
          li {
            div {
              padding: 0 15px;
              line-height: 46px;
              text-decoration: none;
              font-size: 4vw;
              &:hover {
                cursor: pointer;
              }

              &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0px;
                width: 100%;
                height: 3px;
              }

              &.active:before {
                background: #355892;
              }

              &.active {
                background: #fff;
                color: #355892;
                border-bottom: 1px solid #fff;
              }

              &:hover {
                background: #fff;
                color: #355892;
              }
            }
          }
        }

        .tabcontent {
          padding: 0px;
          border: 1px solid #ddd;
          border-top: none;
        }
      }

      .Research_sub_text {
        h1 {
          font-size: 5vw;
        }

        p {
          font-size: 3vw;
        }
      }
    }
  }
}
